<template>
    <div class="q-left-drawer" v-if="!$vuetify.breakpoint.smAndDown">
        <v-navigation-drawer dense v-model="drawer" app v-on:transitionend="collapse" clipped disable-resize-watcher left hide-overlay :expand-on-hover="!toggle" :mini-variant.sync="is_mini" :permanent="true" @input="updateValue()">
            <div :style="{ ...padding }">
                <main-navigation-list @toggle-menu-updated="updateToggle" :toggle="toggle" style="width:260px" ref="navigation_list"></main-navigation-list>            
            </div>
        </v-navigation-drawer>
        <v-navigation-drawer v-model="utilDrawer" fixed temporary>
            <v-list dense>
                <menu-item icon="fas fa-paint-brush" route="StyleGuide">Style Guide</menu-item>
                <menu-item icon="fas fa-columns" route="ComponentGuide">Component Guide</menu-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
import MainNavigationList from './MainNavigationList.vue'
import MenuItem from './MenuItem.vue'

const TOGGLE_LOCAL_STORAGE_KEY = 'q-sidebar-toggle'

const initialToggleValue = window.localStorage.getItem(TOGGLE_LOCAL_STORAGE_KEY) === 'true'

export default {
    props: ['value', 'padding'],
    data: function() {
        return {
            drawer: false,
            utilDrawer: false,
            is_mini: initialToggleValue ? false : true,
            showtoggle: false,
            toggle: initialToggleValue,
        }
    },
    mounted: function() {},
    methods: {
        updateValue() {
            this.$emit('input', this.drawer)
        },
        toggleUtilityDrawer() {
            this.utilDrawer = !this.utilDrawer
        },
        close() {
            this.drawer = false;
            this.updateValue();
        },
        collapse(v) {
            if (this.toggle) {
                return
            }
            if (this.is_mini) {
                this.$refs.navigation_list.collapse()
                this.showtoggle = false
            } else {
                this.showtoggle = true
            }
        },
        updateToggle(updatedToggle) {
            this.toggle = updatedToggle
        }
    },
    watch: {
        'value': function(newV) {
            this.drawer = newV
        },
        $route(to, from) {
            if (this.$vuetify.breakpoint.xs) {
                this.drawer = false;
            }
        },
		toggle(newValue) {
			window.localStorage.setItem(TOGGLE_LOCAL_STORAGE_KEY, newValue)
		}
    },
    components: {
        MenuItem,
        MainNavigationList
    }
}
</script>
