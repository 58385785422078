import { useLocalStorageItem } from "../useLocalStorageItem"
import { ref } from "vue"

export const useUnreadNoticesAlert = () => {
  const lastShownDate = useLocalStorageItem('q:notices:last-shown-alert-date', new Date(0))
  const lastShown = lastShownDate.value

  const today = new Date()

  if (Number(today) - Number(lastShown) > 1000 * 60 * 60 * 24) {
    lastShownDate.value = today

    return {
      doShowAlert: ref(true),
    }
  }

  return {
    doShowAlert: ref(false),
  }
}
