<template>
    <v-app id="inspire" :class="css_class">
        <div class="center-center" v-if="needs_signin">
            <img alt="Quility Dash Board" src="@/assets/images/QuilityLogoSymbolGreenRGB.svg" height="75px"
                class="nav-logo" /><br>
            You must sign in... redirecting to login.
        </div>
        <div class="center-center" v-else-if="!app_ready">
            <div>
                <img alt="Quility Dash Board" src="@/assets/images/QuilityLogoSymbolGreenRGB.svg" height="75px"
                    class="nav-logo" />
                <v-progress-linear indeterminate></v-progress-linear>
            </div>
        </div>
        <div v-else-if="needs_software_agreement || needsAgencyAgreement" class="center-center" style="width: 75vw; text-align: left">
            <div>
                <img
                    alt="Quility Dash Board"
                    contain
                    src="@/assets/images/QuilityLogoHorizontalWhiteRGB_B.svg"
                    :height="$vuetify.breakpoint.smAndDown ? '35px' : '75px'"
                    style="margin-bottom: 25px"
                />
                <q-software-agreement v-if="needs_software_agreement" />
                <q-agency-agreement v-else />
            </div>
        </div>
        <div class="v-application__content" v-else>
            <q-impersonate-banner v-if="isImpersonating"></q-impersonate-banner>
            <right-drawer v-model="drawerRight"></right-drawer>
            <q-app-layout>
                <template #header>
                    <q-appbar v-on:mobileMenu="toggleMobileMenu" v-on:rightMenu="toggleRightMenu" />
                </template>
                <template #sidebar="{ style }">
                    <left-drawer :padding="style"></left-drawer>
                    <mobile-drawer v-model="drawerMobile"></mobile-drawer>
                </template>
                <template #rightbar="{ style }">
                    <summit-drawer :style="{ ...style }"></summit-drawer>
                </template>
                <template #content>
                    <v-content class="full-height" v-if="user">
                        <keep-alive>
                            <router-view class="pb-12"></router-view>
                        </keep-alive>
                        <q-snackbar-notices></q-snackbar-notices>
                        <q-snackbar-jobs></q-snackbar-jobs>
                    </v-content>
                </template>
                <template #footer>
                    <q-footer />
                </template>
            </q-app-layout>
            <q-search-dialog></q-search-dialog>
        </div>
        <q-tour-wrapper></q-tour-wrapper>
        <q-summit-wistia-tracker></q-summit-wistia-tracker>
    </v-app>
</template>
<script>
import LeftDrawer from './components/navigation/LeftDrawer.vue';
import MobileDrawer from './components/navigation/MobileDrawer.vue';
import RightDrawer from './components/navigation/RightDrawer.vue';
import SummitDrawer from './components/summit/sidebar/QSummitSidebarDrawer.vue'
import QAppbar from './components/navigation/QAppbar.vue';
//import DummyData from './store/sample_data/sample_data'
import QSearchDialog from './components/utils/QSearchDialog.vue'
import QAdminNotices from './components/utils/QAdminNotices.vue'
import QSnackbarNotices from './components/utils/QSnackbarNotices.vue'
import QSnackbarJobs from './components/utils/QSnackbarJobs.vue'
import QSummitWistiaTracker from './components/utils/QSummitWistiaTracker.vue'
import GlobalData from './store/global_data'
import { EventBus } from '@/event-bus';
import User from '@/store/Models/User';
import QTourWrapper from '@/components/demo/QTourWrapper.vue';
import ReleaseMeta from '@/ReleaseMeta.json'
import QSoftwareAgreement from '@/components/utils/QSoftwareAgreement.vue';
import QAgencyAgreement from '@/components/utils/QAgencyAgreement.vue';
import QImpersonateBanner from '@/components/utils/QImpersonateBanner.vue';
import { ga_page_view } from '@/GoogleAnalytics'
import QAppLayout from './components/layout/QAppLayout.vue';
import QFooter from './components/navigation/QFooter.vue';
import * as amplitude from '@amplitude/analytics-browser';

const humanizeComponentName = (componentName) => {
    if (!componentName) { return componentName }

    return componentName
        .split('')
        .map((symbol, index, arr) => {
            if (index === 0) { return symbol.toUpperCase() }
            if (symbol === '_') { return '' }
            if (symbol === symbol.toUpperCase()) {
                const next = arr[index + 1]

                if (next && next === next.toUpperCase()) {
                    return symbol
                }

                return ' ' + symbol
            }
            return symbol
        })
        .join('')
}

export default {
    props: {
        source: String,
    },
    data: function () {
        return {
            show: true,
            drawerRight: false,
            drawerMobile: false,
            keywords: null,
            show_results: false,
            data_loaded: false,
            config_loading: false,
            version: ReleaseMeta.Version + "r" + ReleaseMeta.MinorVersion,
            show: false,
            css_class: null
        }
    },
    mounted: function () {

        var g = this;
        EventBus.$on("datainit_complete", (user) => {
            if (g.isImpersonating) {
                var override_role = this.$auth.auth_user['http://quility.com/meta_data'].OverrideRole
                this.$auth.auth_user.email = g.user.Agent.AgentEmail;
                this.$auth.auth_user.name = g.user.Agent.AgentName;
                this.$auth.auth_user['http://quility.com/roles'] = [override_role]
                this.$auth.auth_user['http://quility.com/user_data'].email = g.user.Agent.AgentEmail;
                this.$auth.auth_user['http://quility.com/user_data'].name = g.user.Agent.AgentName;
                user.email = g.user.Agent.AgentEmail
                user.name = g.user.Agent.AgentName
                User.deleteAll()
                User.create({ data: [user] })
                //some folks have multiple divisions assigned... this should set one division by default so we don't confuse people. You can switch divisions in the user menu.
            }
            if (g.raw_divisions.length > 0) {
                if (g.hasDivision(['Field'])) {
                    g.$nextTick(function () {
                        EventBus.$emit('changeDivision', "Field")
                    })
                } else if (g.hasDivision(['Corporate'])) {
                    g.$nextTick(function () {
                        EventBus.$emit('changeDivision', "Corporate")
                    })
                }
            }

            ga_page_view('page_view', {})
            this.$amplitude.initialize(this.user)
      if (this.user.permissions.includes('agent:IntercomUAT')) {
        console.log("Initializing Intercom...");
        this.$intercom.initialize(this.user);
        console.log("Booting Intercom...");
        this.$intercom.boot();
      } else {
        console.log("Initializing Glia...");
        this.$glia.initialize();
        console.log("Booting Glia...");
        this.$glia.boot();
      }
        });
        EventBus.$on("changeDivision", function (d) {
            var user = { ...g.user };
            user.currentSetDivision = d
            User.create({ data: [user] })
        });
        EventBus.$on("background-animation", function (css_class) {
            g.css_class = css_class
        })
        this.addAccessiBe();
    },
    computed: {
        'search': {
            set(v) {
                this.$store.commit('updateShowSearch', v);
            },
            get() {
                return this.$store.state.show_search;
            }
        },
        'app_ready': function () {
            if (!this.$auth.isAuthenticated) {
                this.login();
                return false;
            }
            if (this.user == null) {
                return false;
            }
            return !this.$store.state.entities.user.loading; // || this.$route.name == 'LinkAccount';
        },
        'needs_software_agreement': function () {
            if (this.isImpersonating) {
                return false;
            }
            if (this.user == null) {
                return false;
            }
            return this.getConfig("AcceptedSoftwareAgreement " + ReleaseMeta.Version) !== true
        },
        needsAgencyAgreement() {
            if (this.isImpersonating) {
                return false;
            }
            if (this.user == null) {
                return false;
            }
            if (this.hasRole(['SuperAdmin', 'Staff'])) {
                return false;
            }
            return !this.getConfig("AcceptedAgencyAgreement " + ReleaseMeta.Version)
        },
        'needs_signin': function () {
            return !this.$auth.isAuthenticated
        }
    },
    methods: {
        toggleMobileMenu: function (menus) {
            this.drawerMobile = !this.drawerMobile;
        },
        toggleRightMenu: function (menus) {
            this.drawerRight = !this.drawerRight;
        },
        performSearch: function (e) {
            if (e.keyCode === 13) {
                this.searchResults();
            }
            if (this.keywords == null || this.keywords == '') {
                g.show_results = false
            } else {
                var g = this
                setTimeout(function () {
                    g.show_results = true
                }, 500)
            }
        },
        searchResults: function () {
            var g = this
            this.active = false;
            this.$store.commit('updateShowSearch', false);
            this.$router.push({ name: 'SearchResults', query: { keyword: this.keywords } }).catch(err => {
                g.showError("Whoops! " + err)
            })
        },
        login() {
            this.$auth.loginWithRedirect();
        },
        addAccessiBe: function () {
            var s = document.createElement('script');
            var h = document.querySelector('.app-layout__footer') || document.body;
            s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async = true;
            s.onload = function () {
                acsbJS.init({
                    statementLink: '',
                    footerHtml: '',
                    hideMobile: false,
                    hideTrigger: false,
                    disableBgProcess: false,
                    language: 'en',
                    position: 'left',
                    leadColor: '#005851',
                    triggerColor: '#005851',
                    triggerRadius: '50%',
                    triggerPositionX: 'left',
                    triggerPositionY: 'bottom',
                    triggerIcon: 'people',
                    triggerSize: 'small',
                    triggerOffsetX: 20,
                    triggerOffsetY: 10,
                    mobile: {
                        triggerSize: 'small',
                        triggerPositionX: 'left',
                        triggerPositionY: 'bottom',
                        triggerOffsetX: 20,
                        triggerOffsetY: 10,
                        triggerRadius: '50%'
                    }
                });
            };
            h.appendChild(s);
        },
    },
    watch: {
        //smooth scrolling to a hash on a page.
        '$route.hash': function (hash) {
            if (hash == '') {
                return
            }
            this.$nextTick(function () {
                try {
                    this.$vuetify.goTo(hash, { easing: 'easeInOutCubic', offset: 80 })
                } catch (error) {
                    //console.log("Hash not found")
                }
            })
        },
        //used to wait until we are authenticated before loading the rest of the app.
        '$auth.isAuthenticated': function (newV) {
            var g = this;
            if (newV) {
                this.$nextTick(function () {
                    GlobalData.import_data().then(function () {
                        g.data_loaded = true
                    },
                        function (err) {
                            console.log(err)
                        })
                    //summit data takes to long locally so splittting this out. 
                    GlobalData.import_summit()
                })
            }
        },
        //sets the browser's title to the route name.
        '$route': function (to) {
            this.setPageTitle(to.meta.title || humanizeComponentName(to.name))
        },
        //checks if the user is currently on an unauthorized page. If so try to go back one page.
        //This is because sometimes with direct/deep links into the SPA, the user object has not loaded and htus we don't know if that user has permission to view this route. So they are redirected to a 403 unauthorized.
        //Once the user object loads, this will recheck the permissions and see if they really have access to the resource they wanted.
        'user': {
            deep: true,
            handler: function (newV) {
                if (newV != null) {
                    if (this.$router.currentRoute.path == '/403') {
                        this.$router.go(-1)
                    }
                }
            }
        }
    },
    components: {
        QAppbar,
        LeftDrawer,
        RightDrawer,
        MobileDrawer,
        QSearchDialog,
        QAdminNotices,
        QSnackbarNotices,
        QTourWrapper,
        QSoftwareAgreement,
        QAgencyAgreement,
        QImpersonateBanner,
        QSnackbarJobs,
        QAppLayout,
        QFooter,
        SummitDrawer,
        QSummitWistiaTracker
    }
}

</script>
<style src="@fortawesome/fontawesome-free/css/all.css">

</style>
<style src="./assets/fontello-2e289b48/css/fontello.css">

</style>
<style lang="scss" scoped>
.v-application {
    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .v-content {
            flex: 1;
        }
    }
}
</style>
