<template>
    <div>
        <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
                <v-btn small text fab v-on="on">
                    <v-badge v-if="totalAlerts > 0" color="warning" light :content="totalAlerts">
                        <v-icon small color="warning">fas fa-bell</v-icon>
                    </v-badge>
                    <v-icon v-else small>fas fa-bell</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item :to="{ name: 'SupportTickets'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-support-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ ticketNotices }} Support Ticket{{ ticketNotices != 1 ? 's' : '' }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'Pending Policy'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-new-business</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title><!--{{ pendingpolicycount }}//-->Pending {{ pendingpolicycount != 1 ? 'policies' : 'policy' }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="showNotificationHistoryMenuItem" :to="{ name: 'Notices' }">
                    <v-list-item-icon>
                        <v-icon>fas fa-bell</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ unreadCount ? unreadCount + ' New' : '' }} Messages</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="user.Agent.Flanking.length > 0" :to="{name:'User Profile'}" class="warning">
                    <v-list-item-icon>
                        <v-icon color="black">fas fa-exclamation-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title > <span class="black--text">Flanking Alert</span></v-list-item-title>
                </v-list-item>

                <!--<v-list-item @click="">
                    <v-list-item-title>You have 3 Notifications</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'LeadDashboard'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-leads-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>200 New Leads</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'New Business'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-new-business-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>34 ContractsNeed Attention</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'Engagement'}">
                    <v-list-item-icon>
                        <v-icon>icon-q-engage-select</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>27 Upcoming Annual Reviews</v-list-item-title>
                </v-list-item>
                //-->
            </v-list>
        </v-menu>

        <QUnreadNotices v-if="unreadCount > 0" />

        <QGratitudesJournalNotices />

        <v-snackbar
            v-if="unreadCount > 0"
            v-model="doShowAlert"
            right
            top
            :timeout="0"
            color="warning"
        >
            <div style="width: 100%">
                <div>
                    <v-icon>fas fa-bell</v-icon>
                    <span class="ml-2">You have {{ unreadCount }} unread notification{{ unreadCount != 1 ? 's' : '' }}</span>
                </div>
                <div class="d-flex align-center justify-end" style="width: 100%">
                    <v-btn
                        color="white"
                        class="ma-0"
                        text
                        @click="doShowAlert = false"
                    >
                        Dismiss
                    </v-btn>

                    <v-btn
                        color="white"
                        class="ma-0"
                        text
                        :to="{ name: 'Notices' }"
                        @click="doShowAlert = false"
                    >
                        Read
                    </v-btn>
                </div>
            </div>
        </v-snackbar>

    </div>
</template>

<script>
import SupportTicket from '@/store/Models/SupportTicket'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QAdminNotices from '@/components/utils/QAdminNotices.vue'
import Page from '@/CMS/views/index.vue'
import QUnreadNotices from '@/components/notices/QUnreadNotices.vue'
import QGratitudesJournalNotices from '@/views/Gratitudes/QGratitudesJournalNotices.vue'
import { useUnreadNoticesAlert } from '@/composables/notices/useUnreadNotices'
import { getUnreadNoticesCount } from '@/store/API/PopupAcknowledgements'
import { EventBus } from "@/event-bus";
import { onUnmounted, ref } from "vue";

export default {
    name: "QNotificationMenu",

    components: {
        QAdminNotices,
        QUnreadNotices,
		QGratitudesJournalNotices,
        Page,
    },

    setup() {
	  const unreadCount = ref(0)
	  const refreshUnreadCount = async () => {
		unreadCount.value = await getUnreadNoticesCount()
		if (!unreadCount.value) {
			EventBus.$emit('no-unread-notices')
		}
	  }
	  refreshUnreadCount()
	  EventBus.$on('update:message', refreshUnreadCount);
	  onUnmounted(() => {
		EventBus.$off('update:message', refreshUnreadCount);
	  })

	  const { doShowAlert } = useUnreadNoticesAlert()
        return {
            doShowAlert,
            unreadCount,
        }
    },

    data() {
        return {
            pendingpolicycount: 0,
            show_popups_history: false,
            historyKey: 1
        }
    },

    mounted() {
        this.getpendingpolicycount()
    },

    computed: {
        ticketNotices: function() {
            return SupportTicket.query().where('status', (value) => (value == 'solved' || value == 'pending')).count()
        },
        totalAlerts () {
            let total = this.ticketNotices// + this.pendingpolicycount //removing pendingpolicycount from total for now. 
            if(this.user.Agent.Flanking.length > 0)
                total ++
		  return total + this.unreadCount
        },
        showNotificationHistoryMenuItem: function() {
            return this.user.popups_history && this.user.popups_history.length > 0
        }
    },

    methods: {
        getpendingpolicycount: function(){
            var g = this
            QuilityAPI.getAgentPolicyCountSinceLastVisit(this.user.AgentCode)
            .then((json) => {
                g.$set(g, 'pendingpolicycount', json.Count);
            })
        },
        showNotifications: function() {
            this.show_popups_history = true
            this.historyKey = Math.random()
        }
    },
}
</script>
