import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import AdminRoutes from './admin'
import AdminReportRoutes from './admin_reports'
import MyStats from './mystats'
import UserRoutes from './user'
import LeadRoutes from './leads'
import QMS from './qms'
import ResourceRoutes from './resources'
import NewBusinessRoutes from './newbusiness'
import QuilityURoutes from './quilityu'
import CMSRoutes from './cms'
import SupportRoutes from './support'
import AgencyBuilding from './agency_building'
import StoredProc from './stored_proc'
import Agent from './agent'
import Summit from './summit';
import FormManagementLibrary from './fml';
import MyQuility from './myquility';
import Notices from './notices';
import Carriers from './carriers';
import OnboardedApplicants from './onboarded_applicants'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { ga_page_view } from '@/GoogleAnalytics'

//authentication guards
//we will probably want to have one for each role we have.
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    },
    ...CMSRoutes,
    {
        path: '/search',
        name: 'SearchResults',
        // route level code-splitting
        // this generates a separate chunk (leads.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "engagement" */ '../views/SearchResults.vue'),
        meta: {
            title: 'Search Results'
        },
    },
    {
        path: '/site-index',
        name: 'SiteIndex',
        component: () => import( /* webpackChunkName: "siteIndex" */ '../modules/SiteIndex/views/SiteIndex.vue'),
        meta: {
            title: 'Site Index'
        },
    },
    {
        path: '/admin/linkaccount',
        name: 'LinkAccount',
        // route level code-splitting
        // this generates a separate chunk (leads.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "engagement" */ '../auth/QAuth0Link.vue'),
        meta: {
            title: 'Link Account'
        },
    },
    {
        path: '/admin/linkaccounts',
        name: 'LinkAccounts',
        // route level code-splitting
        // this generates a separate chunk (leads.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "engagement" */ '../auth/LinkAccounts.vue'),
        meta: {
            title: 'Link Accounts'
        },
    },
    ...AdminRoutes,
    ...MyStats,
    ...UserRoutes,
    ...LeadRoutes,
    ...ResourceRoutes,
    ...NewBusinessRoutes,
    ...QuilityURoutes,
    ...SupportRoutes,
    ...AgencyBuilding,
    ...StoredProc,
    ...Agent,
    ...AdminReportRoutes,
    ...QMS,
    ...Summit,
    ...FormManagementLibrary,
    ...MyQuility,
    ...Notices,
    ...Carriers,
    ...OnboardedApplicants,
    { path: '/404', component: () => import( /* webpackChunkName: "engagement" */ '../views/NotFound.vue') },
    { path: '/401', component: () => import( /* webpackChunkName: "engagement" */ '../views/NotAuthorized.vue') },
    { path: '/403', component: () => import( /* webpackChunkName: "engagement" */ '../views/NotAuthorized.vue') },
    { path: '*', redirect: '/404' },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
})
router.beforeEach(authGuard(router));



router.afterEach((to, from) => {

    if (to.name == 'PageView') {
        ga_page_view('page_view', {
            page_title: to.params.page_slug,
            page_path: to.path
        })
        QuilityAPI.logActivty("PageView", to.params.page_slug, to.path, to.params);
    } else if (to.name == 'FormView') {
        ga_page_view('form_view', {
            form_title: to.params.form_slug,
            page_path: to.path
        })
        QuilityAPI.logActivty("FormView", to.params.form_slug, to.path, to.params);
    } else if (typeof to.name != 'undefined' && to.name.indexOf("AgentDetail") > -1) {
        ga_page_view('page_view', {
            page_title: to.params.agentcode,
            page_path: to.path,
        })
        QuilityAPI.logActivty("AgentView", to.params.agentcode, to.path, to.params);
    } else {
        ga_page_view('page_view', {
            page_title: to.name,
            page_path: to.path,
        })
        QuilityAPI.logActivty("PageView", to.name, to.path, to.params);
    }

    //this stops any tours that happen to be in progress when a page loads.
    //if we don't stop the tour, then we can't click the button to start the tour on the next page.
    var v = new Vue();
    if (typeof v.$tours != "undefined") {
        for (var i = 0; i < Object.keys(v.$tours).length; i++) {
            var n = Object.keys(v.$tours)[i];
            v.$tours[n].stop();
        }
    }
});


export default router