<template>
	<router-link class="q-sub-menu-item" v-bind="routerLinkProps">
		<v-list-item @mouseover="hover = true" @mouseleave="hover = false">
			<v-list-item-content>
				<v-list-item-title class="icon-indent">
					<v-icon v-if="icon" left :small="small">{{icon_class}}</v-icon>
					<slot></slot>
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</router-link>
</template>
<script>
export default {
    props: ['icon', 'text', 'href', 'selected', 'small', 'path', 'route'],
    data: () => ({
        hover: false,
        offset: 1000,
    }),


    computed: {
        icon_class: function() {
            if (this.icon.indexOf("icon-q") == 0) {
                if (this.hover || this.selected) {
                    return this.icon + "-select";
                }
            }
            return this.icon;
        },

		routerLinkProps() {
			if (this.href) {
				return {
					href: this.href,
					target: '_blank',
					rel: 'noopener',
					is: 'a'
				}
			}

			if (this.route) {
				if (typeof this.route === 'string') {
					// Sometimes route can be just a path, not name
					if (this.route.startsWith('/')) {
						return {
							to: this.route,
							is: 'router-link'
						}
					}

					return {
						to: { name: this.route },
						is: 'router-link'
					}
				}

				return {
					to: this.route,
					is: 'router-link'
				}
			}

			if (this.path) {
				if (typeof this.path === 'string' && this.path.startsWith('http')) {
					return {
						href: this.path,
						target: '_blank',
						rel: 'noopener',
						is: 'a'
					}
				}

				return {
					to: this.path,
					is: 'router-link'
				}
			}

			throw new Error('No path, href, provided to SubMenuItem')
		}
    },
}
</script>

<style lang="scss">
  .v-application a.q-sub-menu-item:hover {
	text-decoration: none !important;
  }
</style>