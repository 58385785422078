<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn small text fab @click="doShowDrawer = !doShowDrawer" v-bind="attrs" v-on="on">
                <v-icon small>icon-q-summit-icon</v-icon>
                </v-btn>
            </template>
            <span>Summit</span>
        </v-tooltip>
    </div>
</template>
<script>
import { store } from './store/sidebar-store'

export default {
    computed: {
        doShowDrawer: {
            get() { return store.doShowSummitSidebar },
            set(val) { store.doShowSummitSidebar = val }
        }
    }
}
</script>