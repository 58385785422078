import { computed } from 'vue'

const isDate = (date: unknown) => date instanceof Date && !isNaN(date.valueOf())

export const useLocalStorageItem = <Value>(key: string, defaultValue: Value) => {
  return computed<Value>({
    get() {
      const v = localStorage.getItem(key)
      if (!v) {
        localStorage.setItem(key, JSON.stringify(defaultValue))
        return defaultValue 
      }
      const parsed = JSON.parse(v)

      if (isDate(defaultValue)) {
        return new Date(parsed as number) as unknown as Value
      }
  
      return parsed as Value
    },
    set(v: Value) {
      localStorage.setItem(key, JSON.stringify(v))
    }
  })
}