const createIntercomScript = () => {
    const newScriptElement = document.createElement('script');
    newScriptElement.type = 'text/javascript';
    newScriptElement.async = true;
    const APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
    newScriptElement.src = `https://widget.intercom.io/widget/${APP_ID}`;
    const firstScriptElement = document.getElementsByTagName('script')[0];
    firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement);
}
const createFakeIntercom = () => {
    const Intercom = function () {
        Intercom.c(arguments);
    };
    Intercom.q = [];
    Intercom.c = function (args) {
        Intercom.q.push(args);
    };
    return Intercom;
}
export default {
    install(app) {
        app.prototype.$intercom = {
            initialize(user) {
				window.intercomSettings = {
					agent_email: user.email,
					agent_name: user.name,
					name: user.name,
					email: user.email,
					Isagencyowner: user.IsAgencyOwner
				};
				console.log('intercom settings', window.intercomSettings)
                const intercom = window.Intercom;
                if (typeof intercom === 'function') {
                    intercom('reattach_activator');
                    intercom('update', window.intercomSettings);
                } else {
                    window.Intercom = createFakeIntercom();
                    if (document.readyState === 'complete') {
                        createIntercomScript();
                    } else if (window.attachEvent) {
                        window.attachEvent('onload', createIntercomScript);
                    } else {
                        window.addEventListener('load', createIntercomScript, false);
                    }
                }
            },
            boot(options) {
                if (window.Intercom) {
                    const APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
                    window.Intercom('boot', { 
                        api_base: "https://api-iam.intercom.io",
                        app_id: APP_ID,
                        ...options,
                    });
                }
            },
        }
    },
}