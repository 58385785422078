<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu v-if="showSubmenu !== false" has-demo v-on:demo="startDemo()"></q-my-stats-submenu>
        <v-row wrap class="mx-7">
            <v-col class="pa-0" cols="12" v-if="showSubmenu !== false">
                <h3 v-if="agent">
                    {{agent.AgentName}}
                    <v-btn icon @click="viewAgent">
                        <v-icon>fas fa-arrow-circle-right</v-icon>
                    </v-btn>
                </h3>
                <v-row justify="end" v-if="canDispute">
                    <v-btn color="q_green_1 white--text" depressed transition="scroll-y-transition" @click="launchDisputeForm"><strong>Dispute</strong></v-btn><br>
                </v-row>
            </v-col>
            <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
                <v-col cols="12" sm="8" md="9" lg="10" v-if="agent">
                    <v-row wrap>
                        <v-col class="pmx-3" cols="12" md="6" lg="3">
                            <q-score-card-submitted :agent-code="agent_code" :filters="filters" key="submitted" drill-down-route="Drilldown_Submitted"></q-score-card-submitted>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-placed :agent-code="agent_code" :filters="filters" key="placed" :default-goal="default_placed_goal"></q-score-card-placed>
                        </v-col>
                        <v-col v-if="!hasCcsDivision" class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-closeratio :agent-code="agent_code" :filters="filters" key="closeratio"></q-score-card-closeratio>
                        </v-col>
                        <v-col v-if="!hideUniqueWriters" class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-unique-writers :agent-code="agent_code" :filters="filters" key="unique-writers" :default-goal="default_uw_goal" card-style="production"></q-score-card-unique-writers>
                        </v-col>
                        <v-col v-if="!hasCcsDivision" class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-conserved-policies :agent-code="agent_code" :filters="filters" key="conserved-policies" :default-goal="default_conserved_policies_goal" card-style="production"></q-score-card-conserved-policies>
                        </v-col>
                        <v-col v-if="!hasCcsDivision" cols="12">
                            <q-promotion-tracker-two :agentCode="agent_code" :filters="filters" :uw-goal="default_uw_goal" :placed-goal="default_placed_goal" :consecutive-months="consecutive_months"></q-promotion-tracker-two>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="2" v-if="agent">
                    <q-score-card-filters v-model="filters" :agent="agent" v-on:agent="changeAgent" :allow-agent-lookup="allowAgentLookup"></q-score-card-filters>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QScoreCardSubmitted from '@/components/stats/scorecards/QScoreCardSubmitted.vue';
import QScoreCardCloseratio from '@/components/stats/scorecards/QScoreCardCloseratio.vue';
import QScoreCardUniqueWriters from '@/components/stats/scorecards/QScoreCardUniqueWriters.vue';
import QScoreCardPlaced from '@/components/stats/scorecards/QScoreCardPlaced.vue';
import QScoreCardConservedPolicies from '@/components/stats/scorecards/QScoreCardConservedPolicies.vue';
import QPromotionTrackerTwo from '@/components/stats/scorecards/QPromotionTracker2.vue';
import QScoreCardFilters from '@/components/stats/scorecards/QScoreCardFilters.vue';
import PromotionLevels from '@/store/PromotionLevels.json'
import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { isDisputeTimePeriod } from '@/components/utils/BusinessTimeDateDiff'
export default {
    props: {

        showSubmenu: {
            type: Boolean,
            default: true
        },
        allowAgentLookup: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            agentContractLevel: null,
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: null
            },
            agent: null
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        'default_placed_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.APV
        },
		'default_conserved_policies_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.APV
        },
        'default_uw_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.UniqueWriters
        },
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        canDispute: function() {
            return isDisputeTimePeriod()
        },
        'consecutive_months': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.ConsecutiveMonths
        },
        hasCcsDivision() {
            return this.hasDivision(['CCS'])
        },
        hideUniqueWriters: function() {
            // should only be hidden for Key Leader and below when viewing Personal
            const keyLeaderAndBelow = ['Key Leader', 'Team Leader', 'Sales Representative'];
            return keyLeaderAndBelow.includes(this.agent.LeadershipLevel) && this.filters.statType === 'PersonalProduction' || this.hasCcsDivision;
        },
    },

    methods: {
        'startDemo': function() {
            this.$tours['DemoMyScorecard'].start()
        },
        nextPromotionLevel: function(role) {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (role == PromotionLevels[i].ContractLevel) {
                    if (i >= PromotionLevels.length - 1) {
                        return PromotionLevels[i];
                    }
                    return PromotionLevels[i + 1];
                }
            }
            return PromotionLevels[PromotionLevels.length - 1];
        },
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/new_business/');
                return
            }
            this.$router.push('/my_stats/scorecard/new_business/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + this.agent_code);
        },
        launchDisputeForm() {
            // open link to dispute form
            // housed in jot forms
            window.open('https://form.jotform.com/211966371426055', '_blank');
        },
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QScoreCardSubmitted,
        QScoreCardPlaced,
        QScoreCardCloseratio,
        QScoreCardUniqueWriters,
        QMyStatsSubmenu,
        QPromotionTrackerTwo,
        QScoreCardFilters,
		QScoreCardConservedPolicies
    }
}

</script>
